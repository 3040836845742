import './App.css';
import Content from './Components/Content';
import FAQ from './Components/FAQ';
import Features from './Components/Features';
import Footer from './Components/Footer';
import Header from './Components/Header'
import Intro from './Components/Intro';
import Navbar from './Components/Navbar';
import Pricing from './Components/Pricing';
import Why from './Components/Why';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Header/>
      <Intro/>
      <Features/>
      <Pricing/>
      <FAQ/>
      <Why/>
      <Content/>
      <Footer/>
    </div>
  );
}

export default App;
