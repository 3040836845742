import React from 'react'

export default function FAQ() {
    return (
        <div className='margin-top-5 margin-btm-5 display-flex-column  padding-left-right-3'>
            <h3 className='text-align-center'>FAQ</h3>
            <div className='display-flex-row width-40 align-self-center'>
                <div>
                    <div className='margin-top-2'>
                        <h6 className='margin-right-2'><b>What is avopos?</b></h6>
                        <div>
                            Avopos is a tool that helps you build products in three ways; <br/>
                            (a) Keep a repository of Job Stories, User Personas & Product Metrics <br/>
                            (b) Link these to design, dev & research tasks so you don't lose context <br/>
                            (c) Simple to learn feature prioritization framework built in
                        </div>
                    </div>
                    <div className='margin-top-2'>
                        <h6 className='margin-right-2'><b>Who is avopos for?</b></h6>
                        <div>
                            Avopos is ideal for early stage founders and product teams of smaller startups. <br/>
                            Once you have more than 10 dedicated product managers, it won't add much value.
                        </div>
                    </div>
                    <div className='margin-top-2'>
                        <h6 className='margin-right-2 '><b>When do I have to pay?</b></h6>
                        <div>If you are a beta user, you only have to pay after the beta is over.</div>
                    </div>
                    <div className='margin-top-2'>
                        <h6 className='margin-right-2'><b>Who do I go to for support?</b></h6>
                        <div>You can DM me on <a href='https://twitter.com/SymbolOfFlight' className='underline light-black'>Twitter</a></div>
                    </div>
                    <div className='margin-top-2'>
                        <h6 className='margin-right-2'><b>Can my data be exported?</b></h6>
                        <div>Yes of course! It belongs to you after all!</div>
                    </div>
                    <div className='margin-top-2'>
                        <h6 className='margin-right-2'><b>What does avopos mean?</b></h6>
                        <div>
                            It stands for "a very opinionated piece of software". <br/>
                            We don't put the burden of figuring out best practices on you. We figure it out and pass it along to you.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
