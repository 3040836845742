import React from 'react'

export default function Pricing() {
    return (
        <div className='margin-top-5 margin-btm-5 display-flex-row' id='pricing'>
            <div className='pricing-card align-self-center'>
                <div className='display-flex-row justify-content-space-between display-flex-column-mobile'>
                    <div className='text-align-left'>
                        <hr className='grey-line'/>
                        <h3>Pricing during <b>Beta</b></h3>
                        <ul className='grey'>
                            <li>founder support 🙂</li>
                            <li>full feature set</li>
                            <li>unlimited members</li>
                            <li>unlimited viewers</li>
                            <li>unlimited projects</li>
                        </ul>
                    </div>
                    <div>
                        <h1><b>$0</b> per month</h1>
                        <div className="unique-text">$15/mo after the beta</div>
                        <a href='/#home'><button className='white-button'>Join waitlist</button></a>
                    </div>
                </div>

            </div>

      </div>
    )
}
