import React from 'react'
import Job from '../Assets/Jobs.svg'
import Dev from '../Assets/Dev.svg'
import Star from '../Assets/Star.svg'


export default function Intro() {
    return (
        <div className='margin-top-5 margin-btm-5 padding-top-btm-3 text-align-center display-flex-column grey-bg' id='intro'>
            <hr className='black-line'/>
            <h3 className='margin-btm-2'>
                We give product teams <br/>
                <span className=''><del>pretty roadmaps </del> </span> <br/>
                <span className="highlight-container"><span className="highlight">superpowers</span></span>
            </h3>
            <div className='align-self-center width-80 text-align-left display-flex-row margin-btm-2 display-flex-column-mobile'>
                <div className='card display-flex-row'>
                    <img src={Job} alt='job' className='image-30'/>
                    <div className='margin-left-1'>
                        <h5><b>Repository of Job Stories</b></h5>
                        <div className='light-black'>We combined the best of user stories and Jobs-to-be-done theory to help you keep a repository of your insights.</div>
                    </div>
                </div>
                <div className='card display-flex-row mobile-top-btm-margin'>
                    <img src={Dev} alt='Development' className='image-30'/>
                    <div className='margin-left-1'>
                        <h5><b>Keep context in dev work</b></h5>
                        <div className='light-black'>Link development tasks with product metrics and user personas so that you never lose context when shipping new features.</div>
                    </div>
                </div>
                <div className='card display-flex-row'>
                    <img src={Star} alt='Star' className='image-30'/>
                    <div className='margin-left-1'>
                        <h5><b>Best practices built-in</b></h5>
                        <div className='light-black'>We don’t put the burden on you to figure out product mgmt tips & tricks. We built all that into the product.</div>
                    </div>
                </div>
            </div>
            <hr className='black-line'/>
        </div>
    )
}
