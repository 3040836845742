import React from 'react'
import Subscribe from './Subscribe';


export default function Header() {
    return (
        <div id='home' className='margin-top-5 margin-btm-10 text-align-center display-flex-column  '>
            <h1 className='align-self-center width-40' >
            Product Management tool that helps you <br/>
            <span className="highlight-container"><span className="highlight"><b>build better products</b></span></span> ❤️
            </h1>
            <h4 className='width-40 align-self-center light-black margin-btm-2'>
                You don't have to be a product pro <br/>We help streamline user research, job stories, design tasks, dev work & bug tracking
            </h4>
            <Subscribe/>
        </div>
    )
}
