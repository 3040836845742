import React from 'react'
import Logo from '../Assets/Logo.png'

export default function Navbar() {
    return (
        <div className='display-flex-row justify-content-space-between nav'>
            <img className='logo' src={Logo} alt='avopos logo'/>
            <div className='display-flex-row nav-items'>
                <a href='#features'><div className='margin-right-2 light-black'>Features</div></a>
                <a href='#pricing'><div className='margin-right-2 light-black'>Pricing</div></a>
                <a href='#why'><div className='margin-right-2 light-black'>Why we built this</div></a>
            </div>
        </div>
    )
}
