import React from 'react'
import {useState} from 'react'
import FF from '../Assets/FF.svg'
import An from '../Assets/An.svg'

const Why = () => {
    const [isMore, setIsMore] = useState(false);
    const handleClick = () => {
        setIsMore(!isMore);
        console.log(isMore); 
    }

    return (
        <div id='why' className='margin-top-5 margin-btm-5 display-flex-column text-align-center grey-bg padding-top-btm-3'>
            <div className='card width-60 align-self-center'>
                <h3 className='margin-btm-2'><b>Why we built this</b></h3>
                <div className='display-flex-row margin-btm-2 display-flex-column-mobile align-items-center'>
                    <div className='display-flex-row margin-btm-1'>
                        <img className='profile-img margin-left-2' src={FF} alt="Fu Fei" />
                        <div className='text-align-left margin-left-1 align-self-center'>
                            <div><b>Fu Fei</b></div>
                            <div>Student of Product & <br/>Growth</div>
                        </div>
                    </div>
                    <div className='display-flex-row margin-btm-1'>
                        <img className='profile-img margin-left-2' src={An} alt="An" />
                        <div className='text-align-left margin-left-1 align-self-center'>
                            <div><b>Le Thanh An</b></div>
                            <div>Dancing + Coding man <br/>[Funky Coder :D]</div>
                        </div>
                    </div>

                </div>

                 
                <div className='text-align-left margin-left-2 margin-right-2 padding-left-right-3 padding-btm-5'>
                    {isMore ?
                    <div>
                    Letter from Fu Fei...
                    <br/><br/>
                    <h6>Who am I</h6>
                    I'm a full-time product manager who loves building, tinkering with, and thinking about products. I browse ProductHunt and Indie Hackers almost every night. (Some of you are like me.)
                    <br/><br/>
                    Lately, I've been going meta and thinking about "products for products". 
                    <br/><br/>
                    I've been pretty unsatisfied with the current tooling in the market. 
                    <ul>
                    <li> Why is it that Trello *feels* so messy?</li>
                    <li> Why are there so many features in Jira that I never use?</li>
                    <li> Why are PM tool built for extreme flexibility rather than simplicity?</li>
                    <li> Why is the focus on "issue or bug" tracking rather than "user value"?</li>
                    <li> Why solve easy problems like "beautiful roadmaps" but skip over hard problems like validation?</li>
                    </ul>
                    
                    I think it's because the *craft* of product has evolved over the years and the tools are stuck in a different architecture.
                    <br/><br/>
                    The old architecture of tools focused on "Are you shipping product the right way?". 
                    <br/><br/>
                    The hard questions are actually "Am I shipping the right product?".
                    <br/><br/><br/>
                    <h6>Why do I care about this question?</h6>
                    My life's goal is to look back at the products that I've helped build and say "Wow, look at that"
                    <br/><br/>
                    This started out as a thought experiment: 
                    <br/><br/>
                    <blockquote>Could you build a product that can <b>help others</b> build a better product?</blockquote>
                    <br/><br/><br/>
                    <h6>Recruiting a Co-founder</h6>
                    An is one of the most passionate technologist I know. 
                    <br/><br/>
                    We decided the best way to tackle this question was to build a product/ company to try to answer it.
                    <br/><br/><br/> 
                    </div>
                    :
                    <div></div>
                    }
                    <h6>What is avopos?</h6>
                    It's our attempt to design and build a solution to the question:
                    <br/><br/>
                    <blockquote>Could you build a product that can <b>help others</b> build a better product?</blockquote>
                    <br/><br/>
                    We want to take what has been working in the old paradigm of product management and merge it with best practices that we know today.
                    <br/><br/>
                    Here are 5 of our principles:
                    <br/><br/>
                    <b>(a) Scrum & Agile are here to stay- with some tweaks</b>
                    <br/>
                    The iterative 2-week sprint approach to building software has really raised the overall productivity levels of product teams. 
                    BUT we will be experimenting with some minor tweaks here so stay tuned~
                    <br/><br/>
                    <b>(b) Understanding the Jobs theory is crucial</b>
                    <br/>
                    I think the Jobs-to-be-done framework for customer development and innovation has been the most influential framework in the last two decades.
                    <br/>
                    We will be incorporating some of the concepts into our product.
                    <br/><br/>
                    <b>(c) Start from Stories not Data</b>
                    <br/>
                    If you start with data, you will start imagining random theories of why the graphs went up and down- don't imagine theories, it's a trap.
                    <br/>
                    Data should only be used to confirm or deny stories that you've heard from a customer or to gauge it's overall impact. 
                    <br/><br/>
                    <b>(d) UX Design & Research teams should have their own lane</b>
                    <br/>
                    Unlike development work or basic UI mockups, UX design and research projects don't always have a clear scope of work. This makes estimation of work much harder because it doesn't always fit in nicely with the development sprint, but you should cater for it somehow
                    <br/><br/>
                    <b>(e) Flexible tool ≠ Good tool</b>
                    <br/>
                    Flexibility puts the burden on users to figure the best set-up. We want to take on that burden. (avopos = a. very. opinionated. piece. of. software.)
                    <br/><br/><br/>
                    If you have read till this point...
                    <br/>
                    Thank you.
                    <br/><br/><br/>

                    Life is so rich ~
                    <br/>
                    -- Fu Fei 
                    <br/><br/>
                    <button onClick={handleClick} className='read-trigger align-self-right'>
                        {isMore ? <span>Read less </span>: <span><a href='#why' className='light-black'>Read more</a> </span>}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Why