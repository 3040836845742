import React from 'react'
import Medium from '../Assets/Medium.svg'
import Twitter from '../Assets/Twitter.svg'
import Github from '../Assets/Github.svg'

export default function Content() {
    return (
        <div className='margin-top-5 margin-btm-5 padding-top-btm-3 text-align-center display-flex-column' >
            <h3>If you want to follow along...</h3>
            <div className='width-40 align-self-center light-black margin-btm-2'>
                I write & tweet about our journey + product lessons
            </div>
            <div>
                <a href='https://p-zero.fufei.io/' target='_/'><img src={Medium} alt='Medium' className='image-30 '/></a>
                <a href='https://twitter.com/SymbolOfFlight' target='_/'><img src={Twitter} alt='Twitter' className='image-30 margin-left-1 margin-right-1'/></a>
                <a href='https://github.com/lethanhan97' target='_/'><img src={Github} alt='Github' className='image-30'/></a>
            </div>
        </div>
    )
}
