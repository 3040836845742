import React from 'react'
import Logo from '../Assets/Logo.png'

export default function Footer() {
    return (
        <div className='padding-top-btm-3 display-flex-row margin-top-5 grey-bg display-flex-column-mobile '>
            <div className='margin-btm-2 align-self-center text-align-center'>
            <img className='logo' src={Logo} alt='avopos logo'/>
            <div>Build something your users want.</div>
            <br/>
            </div>
            
        </div>
    )
}
