import React from 'react'
import {useState} from 'react'

const Subscribe = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [isSent, setIsSent] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        const convertkit = {
           "api_key": 'SAe6AIfUY2GB0XT4KAQDNQ',
           email,
           "first_name": name
        }
        fetch('https://api.convertkit.com/v3/forms/2297620/subscribe', {
            method:'POST',
            headers: {"Content-Type": "application/json; charset=utf-8"},
            body: JSON.stringify(convertkit)
        })
        .then(()=> {
            console.log('added'); 
            setIsSent(true);
        })
    }

    return(
        <div>
            { !isSent ? 
                <div>
                    <form onSubmit={handleSubmit}>
                    <input 
                        placeholder='First Name'
                        className='input-field'
                        type='text' 
                        required 
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                    />
                    <input 
                        placeholder='Email address'
                        className='input-field'
                        type='email' 
                        required 
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                    <button className='black-button align-self-center'>Join waitlist</button>
                    </form>
                    <div className='subscribe-text'>Get early access | No credit card required | Try the beta for free</div>
                </div>
                :
                <div>Awesome~ We are about 5 weeks from launch. Check your email to confirm 😁</div>
            }
            
        </div>
    );
}

export default Subscribe