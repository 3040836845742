import React from 'react'
import Sprint from '../Assets/Sprint.svg'
import Plan from '../Assets/Plan.svg'
import Track from '../Assets/Track.svg'
import Jobs from '../Assets/1-JTBD.svg'
import UXUI from '../Assets/2-UXUI.svg'
import Framework from '../Assets/3-Framework.svg'
import Metrics from '../Assets/4-Metrics.svg'

export default function Features() {
    return (
        <div className='text-align-center display-flex-column' id='features'>
        <h2>Features</h2>
        <div className="patrick-hand">What you already expect</div>
        <div className='align-self-center width-80 text-align-left display-flex-row margin-btm-2 display-flex-column-mobile align-items-center'>
            <div className='card display-flex-row width-30'>
                <img src={Sprint} alt='Sprint' className='image-30'/>
                <div className='margin-left-1'>
                    <h5><b>Sprints</b></h5>
                    <div className='light-black'>Plan sprints & distribute work across team</div>
                </div>
            </div>
            <div className='card display-flex-row width-30 mobile-top-btm-margin'>
                <img src={Plan} alt='Plan' className='image-30'/>
                <div className='margin-left-1'>
                    <h5><b>Keep context in dev work</b></h5>
                    <div className='light-black'>Create stories and slot them into relevant sprints</div>
                </div>
            </div>
            <div className='card display-flex-row width-30'>
                <img src={Track} alt='Track' className='image-30'/>
                <div className='margin-left-1'>
                    <h5><b>Best practices built-in</b></h5>
                    <div className='light-black'>Prioritize and manage work with complete visibility</div>
                </div>
            </div>
        </div>
        <div className="patrick-hand margin-top-5 "><span className="highlight-container"><span className="highlight">What's new</span></span></div>
        <div className='width-80 align-self-center '>
            <div className='text-align-left align-self-center margin-btm-5'>
                <div className='display-flex-row display-flex-column-mobile align-items-center'>
                    <div className='width-50 align-self-center'>
                        <div className='feature-text-large'>Start from your user’s JTBD</div>
                        <div className='light-black'>
                            <br/>
                            Job’s theory has been the most significant framework we have to think about product development since agile. <br/><br/>
                            We incorporated it into avopos so you that you don’t have to spend time learning it and can focus on attention on what you do best - delighting users. </div>
                    </div>
                    <img className='feature-img margin-right-2' src={Jobs} alt="Jobs to be done" />
                </div>
            </div>
            <div className='text-align-left align-self-center margin-btm-5'>
                <div className='display-flex-row display-flex-column-mobile align-items-center'>
                    <img className='feature-img margin-left-2' src={UXUI} alt="UX & UI" />
                    <div className='width-50 align-self-center'>
                        <div className='feature-text-large'>Incorporate UX & UI work into Sprints</div>
                        <div className='light-black'>
                            <br/>
                            UX research & UI design tasks should get reflected in sprints. <br/><br/>
                            We know that design isn’t always as clear cut as engineering.<br/><br/>
                            A different workflow needs to be present to cater for concept explorations. So, we made sure that their tasks catered for that.
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-align-left align-self-center margin-btm-5'>
                <div className='display-flex-row display-flex-column-mobile align-items-center'>
                    <div className='width-50 align-self-center'>
                        <div className='feature-text-large'>One prioritization framework</div>
                        <div className='light-black'>
                            <br/>
                            There are <b>too many</b> prioritization frameworks (RICE, MOSCOW, 2x2 Effort vs Value, KANO).<br/><br/>
                            We don’t expect you to learn all of them.<br/> 
                            We keep it simple.<br/><br/>
                            We took the best practices from each framework and incorporated it into avopos.<br/>
                            (<a href='https://p-zero.fufei.io/measuring-ranking-user-value-for-prioritization-739b7591c86b' className='underline light-black'>Read more</a>)  
                        </div>
                    </div>
                    <img className='feature-img margin-right-2' src={Framework} alt="Priority Frameworks" />
                </div>
            </div>
            <div className='text-align-left align-self-center margin-btm-5'>
                <div className='display-flex-row display-flex-column-mobile align-items-center'>
                    <img className='feature-img margin-left-2' src={Metrics} alt="Metrics & Personas" />
                    <div className='width-50 align-self-center'>
                        <div className='feature-text-large'>Keep context of  metrics & personas</div>
                        <div className='light-black'>
                            <br/>
                            As you scale, we know it gets hard to keep context of why you are working on certain development tasks. <br/><br/>
                            We make it simple.<br/><br/>
                            Link relevant product metrics & user personas to your design, development & research tasks so that you never lose that context.
                        </div>
                    </div>
                </div>
            </div>
            <div className='margin-top-5 margin-btm-5 text-align-center display-flex-column  '>
                <div className='feature-text-large'>More surprises coming</div>
                <div className='width-40 align-self-center light-black margin-btm-2'>
                    <br/>
                    We aren’t done innovating yet. <br/>
                    We will keep working to ensure that you have the best tool set to build the best possible product for your users ❤️
                </div>
            </div>
        </div>
        
        
        
        </div>
    )
}
